<template>
  <b-nav-item
    v-b-tooltip.hover.bottom="isVideoOnMap ? 'map below video' : 'video on map'"
    @click="layout = isVideoOnMap ? 'map-below-video' : 'video-on-map'"
  >
    <!-- <feather-icon size="21" :icon="`${isVideoOnMap ? 'Book' : 'Layout'}Icon`" /> -->
    <svg
      v-if="isVideoOnMap"
      xmlns="http://www.w3.org/2000/svg"
      width="21px"
      height="21px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-layout"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="3" y1="12" x2="21" y2="12" />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      width="21px"
      height="21px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-layout"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="13" y1="12" x2="21" y2="12" />
      <line x1="12" y1="13" x2="12" y2="21" />
    </svg>
  </b-nav-item>
</template>

<script>
import Vue from 'vue'
import { BNavItem, VBTooltip } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BNavItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const layout = computed({
      get() {
        return store.state.appConfig.layout.layout
      },
      set(val) {
        store.commit('appConfig/UPDATE_LAYOUT', val)
        Vue.$gtag.event('Toggle Layout', { value: val })
      },
    })

    const isVideoOnMap = computed(() => layout.value === 'video-on-map')

    return { layout, isVideoOnMap }
  },
}
</script>
