<template>
  <b-nav-item v-b-tooltip.hover.bottom="isDark ? 'light mode' : 'dark mode'" @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
  </b-nav-item>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BNavItem, VBTooltip } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BNavItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const skin = computed({
      get() {
        return store.state.appConfig.layout.skin
      },
      set(val) {
        store.commit('appConfig/UPDATE_SKIN', val)
      },
    })

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
}
</script>
