<template>
  <div class="navbar-container d-flex content align-items-center" :class="`${showSidebarRight ? 'sidebarOpen' : ''}`">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <video-search v-if="showNavibarSearchVideo" />
      <search-bar v-if="showNavibarSearchPoi && showSidebarRightEditTags" class="d-none d-lg-block" />
      <layout-toggler v-if="showNavibarLayout" class="d-none d-lg-block" />
      <dark-toggler v-if="showNavibarDarkMode" class="d-none d-lg-block" />
      <!-- <edit-toggler v-if="showNavibarEditTags && isUser && canEdit" class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        v-if="user"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ user.name }}</p>
            <span v-if="isAdmin || isSuperUser" class="user-status">
              {{ [isSuperUser ? 'Super' : false, isAdmin ? 'Admin' : false].filter(t => t).join(', ') }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="user.picture"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          v-if="showNavibarEditTags && isUser && canEdit"
          link-class="d-flex align-items-center"
          @click="showSidebarRightEditTags = true"
        >
          <feather-icon size="16" icon="EditIcon" class="mr-50" />
          <span>Edit Tags</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'videos' }">
          <feather-icon size="16" icon="VideoIcon" class="mr-50" />
          <span>Videos</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'gallery' }">
          <feather-icon size="16" icon="ImageIcon" class="mr-50" />
          <span>Gallery</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="isSuperUser"
          link-class="d-flex align-items-center"
          @click="useGoogleMap = !useGoogleMap"
        >
          <feather-icon size="16" icon="MapIcon" class="mr-50" />
          <span>Google Map</span>
          <feather-icon v-if="useGoogleMap" size="16" icon="CheckIcon" class="ml-auto mr-0" />
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item v-else @click="login">Login</b-nav-item>
    </b-navbar-nav>
  </div>
</template>

<script>
import mixinUi from '@/store/ui/mixin'
import mixinTags from '@/store/tags/mixin'
import mixinServer from '@/store/server/mixin'
import store from '@/store'
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BNavItem,
  // BInputGroup,
  // BFormInput,
  // BInputGroupPrepend,
} from 'bootstrap-vue'
import DarkToggler from './DarkToggler.vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import SearchBar from './SearchBar.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
import LayoutToggler from './LayoutToggler.vue'
// import EditToggler from './EditToggler.vue'
import VideoSearch from './VideoSearch.vue'

export default {
  components: {
    // BInputGroup,
    // BFormInput,
    // BInputGroupPrepend,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,

    // Navbar Components
    DarkToggler,
    LayoutToggler,
    // EditToggler,
    SearchBar,
    VideoSearch,
  },
  mixins: [mixinUi, mixinServer, mixinTags],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    login() {
      this.$gtag.event('Click Login')
      store.dispatch('server/login', window.location.href)
    },
    logout() {
      store.dispatch('server/logout').then(() => {
        this.$router.go()
      })
    },
  },
}
</script>

<style scoped>
.input-group-search {
  width: 100px;
}

/* .navbar-container.sidebarOpen {
  margin-right: 420px;
} */
</style>
