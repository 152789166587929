<template>
  <b-form-input
    v-model="videoId"
    style="width: 300px; margin-right: 10px"
    placeholder="YouTube Video ID"
    @keyup.enter.native="searchVideo"
  />
</template>

<script>
import store from '@/store'
import mixinUi from '@/store/ui/mixin'
import { BFormInput } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mixins: [mixinUi],
  data() {
    return {
      videoId: '',
    }
  },
  methods: {
    searchVideo() {
      if (this.videoId) {
        this.$gtag.event('Input Video ID', { value: this.videoId })

        store
          .dispatch('server/getVideo', this.videoId)
          .then(res => {
            this.log(this.videoId)

            store.commit('tags/SET_YOUTUBE_DATA', res.data)

            this.$router.push({ name: 'video', params: { id: this.videoId } })
          })
          .catch(() => {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: 'Could not find video',
                  variant: 'warning',
                },
              },
              {
                position: 'bottom-left',
              }
            )
          })
      }
    },
  },
}
</script>
